import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";
import {debounce} from "lodash-es";


const resetSelect2 = () => {
    $('select:not(.tags)').select2('destroy');
    $('select.tags').select2('destroy');
    $('select:not(.tags)').select2({
        theme: 'bootstrap',
        width: '100%',
    });
    $('select.tags').select2({
        theme: 'bootstrap',
        width: '100%',
        tags: true
    });
}

const scrollToElementWithOffset = () => {
    var element = document.getElementById("statisticsContainer");

    // Get the fixed navbar element
    var navbar = document.getElementById("admin-navbar");

    // Calculate the top offset of the element and the height of the navbar
    var elementPosition = element.getBoundingClientRect().top;
    var navbarHeight = navbar.offsetHeight;

    // Calculate the position to scroll to (element position minus navbar height)
    var offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

    // Scroll to the specific position
    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth' // Optional: add smooth scrolling
    });
}
export default class extends Controller {
    connect() {
        StimulusReflex.register(this);
        // Give the page a chance to load before starting auto refresh
        setTimeout(() => {
            this.toggleAutoRefresh();
        }, 3000);
        this.refreshInterval = null;
    }

    toggleAutoRefresh() {
        this.toggleAutoRefreshUser();
        if ($('#refresh').is(':checked')) {
            // console.log('Starting tasks auto refresh');
            this.startAutoRefresh();
        } else {
            // console.log('Stopping tasks auto refresh');
            this.stopAutoRefresh();
        }
    }

    toggleAutoRefreshUser() {
        this.stimulate('TaskReflex#toggleAutoRefreshUserSetting', $('#refresh').is(':checked'));
    }

    startAutoRefresh() {
        if (this.refreshInterval) return;

        this.refreshInterval = setInterval(() => {
            // console.log('Refreshing tasks');
            // We need UTC timestamp in seconds; and we're giving a 60 second buffer
            $('#timestamp').val(Math.floor(Date.now() / 1000)-60);
            const params = $('form').serialize();
            var timestamp = $('#timestamp').val();
            this.stimulate('TaskReflex#updateTaskList', params, timestamp);
        }, 30000);
        // We aren't rewriting DOM of anything that contains select2, so we no longer need to reset it
        //resetSelect2();
        //debounce(resetSelect2, 100);
    }


    afterUpdateTaskList() {
        //resetSelect2();
        // Scroll to place the statistics container at the top of the page
        // offset the height by the height of the navbar
        // scrollToElementWithOffset();
    }


    stopAutoRefresh() {
        // console.log('Stopping tasks auto refresh');
        clearInterval(this.refreshInterval);
        this.refreshInterval = null;
    }

    disconnect() {
        this.stopAutoRefresh();
    }
}
